.LDroot {
    /* width: 93%; */
    border-top: 1px solid #949393;
    margin: 0 auto;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    /* padding: 15px; */
    
}

.LD1inner{
   padding-left: 3%;
   padding-right: 3%;
}