@font-face {
    font-family: "SF Pro";   /*Can be any text*/
    font-style: normal;
    src: local("SFPRORegular"),
    url("./SFPRODISPLAYREGULAR.OTF") format("truetype");
}

.footer-parent-element {
    display: flex;
    height: 150px;
    font-family: "SF Pro";
    align-items: center;
    justify-content: left;
    padding-left: 17%;
}

.footer-social-media-image {
    width: 58px;
    height: 43px;
}

.footer-heading {
    font-size: 20px;
    font-weight: 400;
}

.footer-link-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 16%;
}

.footer-link {
    text-decoration: underline;
    font-size: 16px;
    color: black;
    margin-right: 15px;
}

.footer-link:hover {
    border-bottom: 1px dotted #000;
    text-decoration: none;
}

.footer-part-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fb-image-icon {
    margin-right: 15px;
}

.navLicenseNumber {
    margin-left: 20px;
}

@media all and (max-width: 600px) {
    .footer-parent-element {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0%;
    }

    .footer-part-1 {
        order: 2;
    }

    .footer-link-bar {
        order: 1;
        margin-bottom: 60px;
        padding-left: 0%;
    }

    .footer-heading {
        font-size: 16px;
    }
}