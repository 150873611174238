@font-face {
    font-family: "SF Pro";   /*Can be any text*/
    font-style: normal;
    src: local("SFPRORegular"),
    url("./SFPRODISPLAYREGULAR.OTF") format("truetype");
}

.home-page-nav-bar-root {
    display: flex;
    flex-direction: row;
    height: 75px;
    text-align: center;
    font-family: "SF Pro";
    align-items: center;
}

.home-page-nav-break-mobile-view {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.home-page-nav-bar-brand {
    justify-content: center;
    text-align: center;
    padding-left: 3%;
}

.home-page-nav-bar-link-menu {
    display: flex;
    flex-direction: row;
    /*margin-left: 15%;*/
    white-space: nowrap;
    padding-left: 10%;
    flex: 1;
}

.home-page-nav-bar-right-part {
    display: flex;
    flex-direction: row;
    /*margin-left: 45%;*/
    align-items: center;
    white-space: nowrap;
    justify-content: right;
    padding-right: 3%;
    flex: 6;
    color: black;
}

.home-page-nav-bar-link {
    text-decoration: underline;
    font-size: 16px;
    margin-right: 45px;
    color: black;
}

.home-page-nav-bar-link:hover {
    border-bottom: 1px dotted #000;
    text-decoration: none;
}

.home-page-nav-bar-email {
    height: 19px;
    width: 19px;
    margin-right: 5px;
    margin-top: 5px;
}

.home-page-nav-bar-ellipse {
    height: 5px;
    width: 5px;
    margin-left: 15px;
    margin-right: 15px;
}

.home-page-nav-bar-telephone {
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.home-page-nav-bar-email-address {
    text-decoration: underline;
    color: black;
}

a:-webkit-any-link {
    color: black;
}

@media all and (max-width: 900px) {
    .home-page-nav-bar-root {
        flex-direction: column;
        margin-bottom: 50px;
    }

    .home-page-nav-bar-email-address {
        display: none;
    }

    .home-page-nav-bar-telephone-number {
        display: none;
        color: black;
    }

    .home-page-nav-bar-brand {
        padding-top: 22px;
        padding-bottom: 22px;
    }

    .home-page-nav-bar-link-menu {
        padding-left: 5%;
    }

    .home-page-nav-break-mobile-view {
        height: 60px;
        margin-bottom: 35px;
        padding-bottom: 15px;
        padding-top: 15px;
        border-top: 1px solid #949393;
    }

    .home-page-nav-bar-ellipse {
        display: none;
    }

    .home-page-nav-bar-email {
        margin-right: 15px;
        margin-top: 5px;
    }
}