.modal {
    display: none; /* Start hidden */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex; /* Flexbox layout for centering */
    justify-content: center;
    align-items: center;
    max-height: 100%; /* Maximum height for the modal content */
    overflow-y: auto; /*Vertical scrollbar when content is too tall */
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 13px;
}

.modal-content {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    text-align: left;
    max-width:500px;
}
#submit-button{
    font-size: 16px;
    border: white 0px;
    padding: 8px;
    background-color: white;
    text-decoration: underline;
    align-items: right;
    text-align: right;
    color:#000000;
    
}

@media (max-width: 600px) {
    .modal-content {
        max-width: 90%; /* Make the modal narrower on small screens */
        font-size: 11px; /* Smaller font for better readability on mobile */
        max-height: 100%; /* Maximum height for the modal content */
    overflow-y: auto; /*Vertical scrollbar when content is too tall */

    }
}